import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import {
  CrmEndpoint,
  CrmEndpointDecorator,
  CrmEndpointListResponse,
} from 'common-module/endpoint';
import { Observable, of, switchMap, throwError } from 'rxjs';

import { PatientModel } from './model/patient.model';

@Injectable({ providedIn: 'root' })
export class PatientsApiService {
  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'patients',
  })
  protected endpoint!: CrmEndpoint<PatientModel>;

  public list(
    params: Params,
  ): Observable<CrmEndpointListResponse<PatientModel>> {
    return this.endpoint.list({ params });
  }

  public getByOwner(id: string) {
    return this.list({ owner: id, $limit: 1 }).pipe(
      switchMap((response) => {
        if (response.data.length) {
          return of(response.data[0]);
        }

        return throwError(
          () => new Error(`Patient by owner '${id}' not found`),
        );
      }),
    );
  }

  public update<Data>(id: string, data: Data): Observable<PatientModel> {
    return this.endpoint.update(id, data);
  }
}
